import React from 'react'
import {BsGithub} from 'react-icons/bs'
import {FaDiscord} from 'react-icons/fa'
import {BsYoutube} from 'react-icons/bs'

const HeaderScoials = () => {
  return (
    <div className="header__socials">
        <a href="https://github.com/TheRealHiThere" target="_blank"><BsGithub/></a>
        <a href='https://discord.com/users/734540262000295958' target="_blank"><FaDiscord/></a>
        <a href='https:/youtube.com/TheRealHiThere' target="_blank"><BsYoutube/></a>
    </div>
  )
}

export default HeaderScoials