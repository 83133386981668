import React from 'react'
/*import CV from '../../assets/cv.pdf */

function CTA() {
  return (
    <div className='cta'>
        <a href='https://drive.google.com/file/d/15GPGrKX616303LalYgcscrZ297gn5DB4/view?usp=sharing' download className='btn'>Download CV</a>    
        <a href='#contact' className='btn btn-primary'>Let's Talk</a>
    </div>
  )
}

export default CTA