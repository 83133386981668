import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/PythonChess.png'
import IMG2 from '../../assets/MyWebSite.png'
import IMG3 from '../../assets/Kojins.png'
import IMG4 from '../../assets/Nidavellir2.png'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG1} alt="" />
          </div>
          <h3>Python Chess Engine</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/TheRealHiThere" className='btn'>GitHub</a>
            <a href="https://github.com/TheRealHiThere/Basic-Python-Chess-Engine" className='btn btn-primary' target='_blank'>Repositories</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG2} alt="" />
          </div>
          <h3>This website!</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/TheRealHiThere" className='btn'>GitHub</a>
            <a href="https://github.com/TheRealHiThere?tab=repositories" className='btn btn-primary' target='_blank'>Repositories</a>
          </div>
        </article>

         <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG3} alt="" />
          </div>
          <h3>One of my most recent video</h3>
          <div className="portfolio__item-cta">
            <a href="https://www.youtube.com/c/TheRealHiThere/videos" className='btn'>My Channel</a>
            <a href="https://www.youtube.com/watch?v=k3ScCI_fDwc" className='btn btn-primary' target='_blank'>Video</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG4} alt="" />
          </div>
          <h3>My Most Popular Video</h3>
          <div className="portfolio__item-cta">
            <a href="https://www.youtube.com/c/TheRealHiThere/videos" className='btn'>My Channel</a>
            <a href="https://www.youtube.com/watch?v=VFRdV4e_tpk" className='btn btn-primary' target='_blank'>Video</a>
          </div>
        </article>
      </div>
    </section>
  )
}

export default Portfolio